import { result } from 'lodash'
import * as Request from '../../request'
import * as Common from '../common'
import * as Actions from './actions'
import * as Constants from './constants'
import * as Types from './types'

const emptyState: Types.UnsubscribeComponentState = {
  ...Common.emptyState,
  ...Common.emptyScheduleState,
  unsubscribed: false,
  isFetchingMemberSchedule: true,
  isLoading: false,
  otherCommunities:[],
  updatedCommunitiesSubscriptions: []
}

type Action = Request.SuccessAction &
 Request.GroupRequestSuccessAction &
 Actions.SubmitUnsubscribeAction &
 Actions.ToggleSubscriptionAction &
 Common.SetGuidAction &
 Common.ScheduleChangeAction
export const reducer =
  (state: Types.UnsubscribeComponentState = emptyState, action: Action)
  : Types.UnsubscribeComponentState => {
  switch (action.type) {
    case Request.successConstant(Common.FETCH_CONFIGURATION):
      return {
        ...state,
        ...Common.configurationsState(action.payload.response),
      }
    case Request.requestConstant(Constants.SUBMIT_SUBSCRIBE):
    case Request.requestConstant(Constants.SUBMIT_UNSUBSCRIBE):
    case Request.requestConstant(Common.SUBMIT_SCHEDULE_CHANGE):
      return {
        ...state,
        ...Common.submittedState(state),
        isLoading: true,
        isScheduleChanged: false,
      }
    case Constants.FAKE_SUBMIT_UNSUBSCRIBE_SUCCESS:
    case Request.successConstant(Constants.SUBMIT_UNSUBSCRIBE):
      return {
        ...state,
        ...Common.returnedState(state),
        unsubscribed: true,
        isLoading: false,

      }
    case Constants.FAKE_SUBMIT_SUBSCRIBE_SUCCESS:
    case Request.successConstant(Constants.SUBMIT_SUBSCRIBE):
      return {
        ...state,
        ...Common.returnedState(state),
        unsubscribed: false,
        isLoading: false,
      }
    case Request.successConstant(Common.SUBMIT_SCHEDULE_CHANGE):
      return {
        ...state,
        ...Common.returnedState(state),
        isLoading: false,
        isScheduleChanged: true,
      }
    case Request.errorConstant(Common.SUBMIT_SCHEDULE_CHANGE):
    case Request.errorConstant(Common.FETCH_MEMBER_SCHEDULE):
    case Request.errorConstant(Constants.SUBMIT_SUBSCRIBE):
    case Request.errorConstant(Constants.SUBMIT_UNSUBSCRIBE):
      return {
        ...state,
        ...Common.erroredState(state),
      }
    case Request.successConstant(Constants.MEMBER_COMMUNITIES):
      return {
        ...state,
        otherCommunities: action.payload.response.results,
      }
    case Request.successConstant(Common.FETCH_ALL_SCHEDULES):
      return {
        ...state,
        ...Common.scheduleState(action.payload.response, state),
      }
    case Request.successConstant(Common.FETCH_MEMBER_SCHEDULE):
      const results = action.payload.response.results
      const scheduleId = results.length > 0 ? results[0].data.schedule_id : 0
      const unsubscribed = results.length > 0 ? !results[0].data.subscribed : false
      return {
        ...state,
        isFetchingMemberSchedule: false,
        ...Common.memberScheduleState(scheduleId, unsubscribed, state),
      }
    case Request.errorConstant(Common.FETCH_MEMBER_SCHEDULE):
      return {
        ...state,
        isFetchingMemberSchedule: false,
        ...Common.returnedState(state),
        ...Common.memberScheduleState(0, null, state),
      }
    case Common.SET_GUID:
      return {
        ...state,
        ...Common.setMemberGuidInState(action.guid, state),
      }
    case Common.UPDATE_SCHEDULE:
      return {
        ...state,
        ...Common.memberScheduleState(action.id, null, state, true),
      }
    case Request.requestConstant(Common.SUBMIT_SCHEDULE_CHANGE):
      return {
        ...state,
        ...Common.submittedState(state),
      }
    case Request.successConstant(Common.SUBMIT_SCHEDULE_CHANGE):
    case Request.errorConstant(Common.SUBMIT_SCHEDULE_CHANGE):
      return {
        ...state,
        ...Common.returnedState(state),
        schedule: {
          ...state.schedule,
          isDirty: false,
        }
      }
    case Constants.TOGGLE_SUBSCRIPTION:
      const { identifier, isSubscribed, guid } = action.community;
      let updatedCommunitiesSubscriptions = state.updatedCommunitiesSubscriptions
      if (isSubscribed) {
        updatedCommunitiesSubscriptions.push({ identifier, guid })
      } else {
        updatedCommunitiesSubscriptions = updatedCommunitiesSubscriptions.filter(x => x.identifier !== identifier)
      }

      return {
        ...state,
        updatedCommunitiesSubscriptions: updatedCommunitiesSubscriptions,
        otherCommunities: state.otherCommunities.map(community =>
          community.identifier === identifier
            ? { ...community, isSubscribed: isSubscribed }
            : community
        )
      };
    default:
      return state
  }
}
