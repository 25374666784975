export const URL_HOME = '/articles'

export const TOP_ARTICLES_INITIAL_DATA = 'TOP_ARTICLES_INITIAL_DATA'
export const DEFAULT_SOURCE = 'articles-page'

export const FETCH_TOP_ARTICLES = 'FETCH_TOP_ARTICLES'

export const SHORT_DESCRIPTION_LENGTH = 170
export const SHORT_TITLE_LENGTH = 100

export const DESKTOP_LEAD_IMAGE_SIZE = 220
export const DESKTOP_REGULAR_IMAGE_SIZE = 136
export const MOBILE_IMAGE_WIDTH = 310
export const MOBILE_LEAD_IMAGE_HEIGHT = 150
export const MOBILE_REGULAR_IMAGE_HEIGHT = 120

export const TOP_ARTICLES_SUBMIT_SIGNUP = 'TOP_ARTICLES_SUBMIT_SIGNUP'
export const TOP_ARTICLES_SUBMIT_SIGNUP_WITH_RECAPTCHA = 'TOP_ARTICLES_SUBMIT_SIGNUP_WITH_RECAPTCHA'
export const TOP_ARTICLES_UPDATE_SIGNUP_FIELD = 'TOP_ARTICLES_UPDATE_SIGNUP_FIELD'

export const SIGNUP_FIRST_LINE_TEXT = 'Like what you see? Sign up today.'
export const SIGNUP_SECOND_LINE_TEXT = (communityName: string) => {
  return `Sign up for the ${communityName} newsletter to receive articles like this, customized for your interests.`
}

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
export const DATE_LOCALE = "en-US"
