import queryString from 'query-string'
import { filter, map, mergeMap, switchMap} from 'rxjs/operators'
import * as Redux from 'redux'
import * as ReduxObservable from 'redux-observable'
import * as Router from '../../router'
import { getRecaptchaToken } from '../recaptcha'
import * as Actions from './actions'
import * as Constants from './constants'

const queryParams = (routerContext: any): any => {
  return queryString.parse(routerContext.search)
}

const initialData = (onEnterAction: any): any => {
  return {
    ...onEnterAction.params,
    ...queryString.parse(onEnterAction.routerContext.search),
  }
}

const onEnter = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {history, params}: any,
) => action$.ofType(Router.ON_ENTER).pipe(
  filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
  mergeMap((action: Router.OnEnterAction) => ([
    Actions.setInitialData(initialData(action)),
    Actions.fetchTopArticles(
      action.params.community_identifier,
      action.params.issue_identifier,
      action.params.article_identifier
    ),
  ])),
)

const submitSignupWithRecaptcha = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  state$: any,
  {history}: any,
) => action$.ofType(Constants.TOP_ARTICLES_SUBMIT_SIGNUP_WITH_RECAPTCHA).pipe(
  switchMap(() => getRecaptchaToken('pagesSignup', state$.value.config.recaptcha_key)),
  map((token: any) => {
    return Actions.submitSignup({
    ...state$.value.topArticles,
    signup: {
      ...state$.value.topArticles.signup,
      recaptcha: token,
      }
    })
  }),
)

export const epic = ReduxObservable.combineEpics( onEnter, submitSignupWithRecaptcha )
