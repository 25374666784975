import * as Request from '../../request'
import * as Constants from './constants'
import * as Common from '../common'
import * as CommonTypes from '../common/types'
import { CommunityState, UpdatedCommunitiesSubscriptions } from './types'

type RequestType = string

const payloadForRequestType = (rt: RequestType) =>
  rt === Constants.SUBMIT_SUBSCRIBE ? Constants.SUBSCRIBE_PAYLOAD : Constants.UNSUBSCRIBE_PAYLOAD

export interface ToggleSubscriptionAction {
  type: typeof Constants.TOGGLE_SUBSCRIPTION;
  community: CommunityState
}

export interface UnsubscribePayload {
  RequestType: RequestType,
  guid: Common.Guid,
  source: string,
  notify: boolean,
  timestamp: number,
  updatedCommunitiesSubscription: UpdatedCommunitiesSubscriptions;
}

interface SubmitUnsubscribePayload extends Request.RequestOptions {
  payload: UnsubscribePayload
}

export type SubmitUnsubscribeAction = Request.RequestAction<SubmitUnsubscribePayload>
export type UnsubscribeSubCommunityAction = (community: CommunityState) => ToggleSubscriptionAction
export type UnsubscribeActionCreator = (guid: Common.Guid, notify: boolean, source: string, updatedCommunitiesSubscription: UpdatedCommunitiesSubscriptions)
  => SubmitUnsubscribeAction
export type FetchAllCommunitiesForMemberActionCreator = (communityIdentifier: Common.CommunityIdentifier, icpGuid: Common.Guid)
  => Request.RequestAction<Request.RequestOptions>

type UnsubscribeActionCreatorCreator = (actionType: RequestType) => UnsubscribeActionCreator

const genericCreator: UnsubscribeActionCreatorCreator = (requestType: RequestType) =>
  (guid: Common.Guid, notify: boolean, source: string, updatedCommunitiesSubscription: UpdatedCommunitiesSubscriptions) =>
    Request.requestActionCreator<SubmitUnsubscribePayload>(requestType)
  ({
    method: 'POST',
    payload: {
      notify,
      guid,
      RequestType: payloadForRequestType(requestType),
      source,
      timestamp: Date.now(),
      updatedCommunitiesSubscription,
    },
    url: `/unsubscribe`,
  })

export const fetchCommunitiesForMember: FetchAllCommunitiesForMemberActionCreator =
  (communityIdentifier: CommonTypes.CommunityIdentifier, icpGuid: Common.Guid) =>
    Request.requestActionCreator<Request.RequestOptions>(Constants.MEMBER_COMMUNITIES)
    ({
      method: 'GET',
      public: true,
      url: `/public/communities/${communityIdentifier}/${icpGuid}`,
    })

export const toggleSubscription = (community: CommunityState): ToggleSubscriptionAction => ({
  type: Constants.TOGGLE_SUBSCRIPTION,
  community: community,
});

export const resubscribe: UnsubscribeActionCreator = genericCreator(Constants.SUBMIT_SUBSCRIBE)
export const unsubscribe: UnsubscribeActionCreator = genericCreator(Constants.SUBMIT_UNSUBSCRIBE)
export const handleScheduleChange = (id: Common.ScheduleId) => ({ type: Common.UPDATE_SCHEDULE, id })
