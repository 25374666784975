import * as React from 'react'
import {connect} from 'react-redux'
import { Button, ButtonGroup, Col, Container, Row} from 'reactstrap'
import * as Common from '../common'
import * as Actions from './actions'
import * as Types from './types'
import { Schedule } from '../preferences/schedule/component'
import { HeaderTypes } from '../common'

interface DispatchProps {
  onChange: Common.ScheduleChangeActionCreator,
  submitUpdateSchedule: Common.UpdateScheduleActionCreator,
  resubscribe: Actions.UnsubscribeActionCreator,
  unsubscribe: Actions.UnsubscribeActionCreator,
  toggleSubscription: Actions.UnsubscribeSubCommunityAction;
}

interface WrappedUnsubscribeState { unsubscribeState: Types.UnsubscribeComponentState }

const skipNotification = (configurations: Common.KeyValue[]) => {
  return Common.isTruthy(Common.keyValue(configurations, 'skip_unsubscribe_confirmation'))
}

type Props = DispatchProps & WrappedUnsubscribeState
export const Unsubscribe =
  ({ unsubscribeState, onChange, submitUpdateSchedule, resubscribe, unsubscribe, toggleSubscription}: Props) =>
    <div>
      <Common.PublicHeader configurations={unsubscribeState.configurations} headerType={HeaderTypes.UNSUBSCRIBE} />
      <Container>
        {!unsubscribeState.errored &&
        <Row>
          <Col>
            {unsubscribeState.unsubscribed ?
              <header className="unsub-page-header text-center"
                dangerouslySetInnerHTML={{ __html: Common.keyValue(unsubscribeState.configurations, 'resubscribe_header_text') }}></header> :
              <header className="unsub-page-header text-center"
              dangerouslySetInnerHTML={{ __html: Common.keyValue(unsubscribeState.configurations, 'unsubscribe_header_text') }}></header>}
          </Col>
        </Row>}
        {unsubscribeState.errored ?
         <div className="text-center">We're sorry to see you go.</div>
         : unsubscribeState.isFetchingMemberSchedule ?
          <Common.BouncingBalls /> :
          <div>
            <Row className="schedule-row">
              <Col>
                {!unsubscribeState.unsubscribed && unsubscribeState.schedule.schedules.length > 1 &&
                  <Schedule label="Would it be helpful if we changed your frequency instead?"
                            schedules={unsubscribeState.schedule.schedules}
                            onChange={onChange} />}
                {!unsubscribeState.unsubscribed && unsubscribeState.schedule.schedules.length < 2 &&
                  <div className="text-center"
                    dangerouslySetInnerHTML={{ __html: Common.keyValue(unsubscribeState.configurations, 'unsubscribe_header_description') }}></div>
                }
              </Col>
            </Row>
            {Common.isTruthy(Common.keyValue(unsubscribeState.configurations, 'multi_newsletter_unsubscribe', "false")) && !unsubscribeState.unsubscribed && <Row className="schedule-row">
                <Col className="text-center">
                  <p>Also unsubscribe me from these newsletters from <strong>{Common.keyValue(unsubscribeState.configurations, 'name', '')}</strong></p>
                  <ul className="community-list">
                    {unsubscribeState.otherCommunities && unsubscribeState.otherCommunities.map((community: Types.CommunityState) =>
                      <li key={community.identifier}>
                        <div className="community-item">
                          <p className="community-name">
                            {community.name}
                          </p>

                          <input type="checkbox" checked={community.isSubscribed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => toggleSubscription({...community, isSubscribed: e.target.checked})} />
                        </div>
                      </li>)}
                  </ul>
                </Col>
            </Row>}
            <Row className="schedule-row">
              <Col className="text-center">
                <ButtonGroup>
                  {!unsubscribeState.unsubscribed && unsubscribeState.schedule.schedules.length > 1 &&
                    <Button style={{ backgroundColor: Common.keyValue(unsubscribeState.configurations, 'primary_color', '') }}
                            disabled={!unsubscribeState.schedule.isDirty || unsubscribeState.isLoading}
                            onClick={() =>
                              submitUpdateSchedule(
                                unsubscribeState.identifier,
                                unsubscribeState.schedule.selectedScheduleId,
                                Common.getMemberGuidFromState(unsubscribeState))} >
                            Update Schedule
                    </Button>}
                  {unsubscribeState.unsubscribed ?
                    <Button style={{ backgroundColor: Common.keyValue(unsubscribeState.configurations, 'resubscribe_button_color', '') }}
                            disabled={unsubscribeState.isLoading}
                            onClick={() =>
                              resubscribe(
                                Common.getMemberGuidFromState(unsubscribeState),
                                !skipNotification(unsubscribeState.configurations),
                                'unsubscribe-page', unsubscribeState.updatedCommunitiesSubscriptions)}>
                      {Common.keyValue(unsubscribeState.configurations, 'resubscribe_button_text')}
                    </Button> :
                    <Button style={{ backgroundColor: Common.keyValue(unsubscribeState.configurations, 'resubscribe_button_color', '') }}
                            disabled={unsubscribeState.isLoading}
                            onClick={() =>
                              unsubscribe(
                                Common.getMemberGuidFromState(unsubscribeState),
                                !skipNotification(unsubscribeState.configurations),
                                'unsubscribe-page', unsubscribeState.updatedCommunitiesSubscriptions)}>
                      {Common.keyValue(unsubscribeState.configurations, 'unsubscribe_button_text')}
                    </Button>}
                </ButtonGroup>
              </Col>
            </Row>
            <Row className="schedule-row">
              <Col className="text-center">
                { unsubscribeState.isLoading ?
                   <Common.BouncingBalls/>
                   : unsubscribeState.isScheduleChanged ?
                     <header className="unsub-page-header text-center">Your schedule preferences have been updated.</header>
                     : null }
              </Col>
            </Row>
          </div>}
      </Container>
    </div>

export const UnsubscribeConnected = connect(
  ({unsubscribeState}: WrappedUnsubscribeState) => ({unsubscribeState}),
  {
    onChange: Actions.handleScheduleChange,
    resubscribe: Actions.resubscribe,
    submitUpdateSchedule: Common.updateMemberSchedule,
    unsubscribe: Actions.unsubscribe,
    toggleSubscription: Actions.toggleSubscription
  },
)(Unsubscribe)
