import * as Request from '../../request'
import * as CommonTypes from '../common/types'
import * as Constants from './constants'
import * as Types from './types'


type FetchTopArticlesActionCreator =
  (communityIdentifier: CommonTypes.CommunityIdentifier, issueIdentifier: string, articleIdentifier: string) =>
  Request.RequestAction<Request.RequestOptions>

const topArticlesUrl = (
  communityIdentifier: string,
  issueIdentifier: string,
  articleIdentifier: string): string => {
  if (issueIdentifier) {
    if (articleIdentifier) {
      return `/top-articles/${communityIdentifier}/${issueIdentifier}/${articleIdentifier}?source=original`
    } else {
      return `/top-articles/${communityIdentifier}/${issueIdentifier}?source=original`
    }
  } else {
    return `/top-articles/${communityIdentifier}?issue_id=-1&source=original`
  }
}

export const fetchTopArticles: FetchTopArticlesActionCreator =
  (communityIdentifier: CommonTypes.CommunityIdentifier, issueIdentifier: string, articleIdentifier: string) =>
    Request.requestActionCreator<Request.RequestOptions>(Constants.FETCH_TOP_ARTICLES)
    ({
      method: 'GET',
      public: true,
      url: topArticlesUrl(communityIdentifier, issueIdentifier, articleIdentifier),
    })

export const updateSignupField = (field: CommonTypes.UpdateField) =>
  ({type: Constants.TOP_ARTICLES_UPDATE_SIGNUP_FIELD, field})

export interface SubmitSignupPayload extends Request.RequestOptions {
  payload: Types.SignupState }
export type SubmitSignupAction = Request.RequestAction<SubmitSignupPayload>
export type SubmitSignupActionCreator = (topArticles: Types.TopArticlesComponentState) => SubmitSignupAction

export const submitSignupWithRecaptcha = (topArticles: Types.TopArticlesComponentState) => ({ type: Constants.TOP_ARTICLES_SUBMIT_SIGNUP_WITH_RECAPTCHA, topArticles })

export const submitSignup: SubmitSignupActionCreator =
  (topArticles: Types.TopArticlesComponentState) =>
    Request.requestActionCreator<SubmitSignupPayload>(Constants.TOP_ARTICLES_SUBMIT_SIGNUP)
    ({
      method: 'POST',
      payload: topArticles.signup,
      public: false,
      url: `/signup/${topArticles.identifier}`,
    })

export const setInitialData = (params: any) =>
  ({type: Constants.TOP_ARTICLES_INITIAL_DATA, params})
